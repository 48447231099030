@import "./../../../styles/theme/variables";

.big-badge-image {

    display: flex;
    background-color: $gray-100;
    border-radius: 100px;
    padding: 10px;
    align-items: center;
    position: relative;
    z-index: 0;

    min-width: 250px;
    transition: ease-in-out 0.2s;

    &--clickable{
        cursor: pointer;
        &:hover{
            background-color: $gray-200;
        }
    }
    &__delete-btn{
        
        position: absolute;
        top: -2px;
        right: -2px;
        background-color: black;
        z-index: 1;;
    
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: ease-in-out 0.2s;

    }

    &__plus-icon-right{
        margin-right: 10px;
        opacity: 0.9;
    }

}

.image-container{
    margin-right: 10px;
    &__image{
        width: 70px;
        height: 70px ;
        border-radius: 100px;
        font-size: 0.5rem;
    }
}

.text-container{
    flex: 1;
    width: 150px;
    &__title{
        font-size: 1rem;
        font-weight: 500;
        color: $secondaryColor-500;
        margin: 0;
        text-transform: lowercase;

        span {
            font-weight: 100;
           
        }
        &::first-letter {
            text-transform: capitalize;
        }
    }

    &__sub-title{
        font-size: 0.8rem;
        font-weight: 200;
        color: $gray-600;
        margin: 0;
        //text-transform: capitalize;
    }

    &__message{
        margin-top: 5px;
        font-size: 0.65rem;
        font-weight: 200;
        color: $gray-600;
        width: 150px;
;
        //text-transform: capitalize;
    }
}

@import './../../../styles/theme/variables';

.team-list-page{


    &__title{
        font-size: clamp(2rem, 10vw, 50px);
        font-weight: 400;
        color: $secondaryColor-500;
        margin-bottom: 40px;
    }
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

@import './../../../styles/theme/variables';

.page{
    max-width: 800px;
    &__title{
        font-size: clamp(2rem, 8vw, 40px);
        font-weight: 400;
        color: $secondaryColor-500;
        margin-bottom: 40px;
    }
}
@import'./../../../styles/theme/variables.scss';
.single-team{
 &__return-btn{
   margin-bottom: 20px;
 }
}

.loader-screen{
    display: flex;
    justify-content: center;
    margin-top: 30%;
    height: 100vh;

}
.body{
    display: flex;
    margin-top: 20px;
}

.title{
    font-size: 2rem;
    font-weight: 400;
    color: $secondaryColor-500;
    margin-bottom: 10px;
}

.body-left-container{
    flex: 1;
    max-width: 300px;
    margin-right: clamp(50px, 15vw, 300px);
    

    &__member-list{
   
    }

}

.body-right-container{
    flex: 1;
    

}

.manager-bloc{
    &__title{

    
        color: $secondaryColor-500;
        margin-bottom: 10px;
    }
}

.project-bloc{
    margin-top: 80px;
    &__title{

        color: $secondaryColor-500;
        margin-bottom: 10px;
    }
}

.spending-demand-list-bloc{
    margin-top: 150px;
    &__spending-demand-list{
        margin-left: clamp(20px, 2vw, 200px);
    }

}
.spending-list{
list-style: none;
margin: 0;
padding: 0;
}

.spending-list-item{
    margin: 20px 0;
    padding: 0;

    max-width: 500px;
}
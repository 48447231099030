@import '../../../../../styles/theme/variables';
.search-pick-employee {
    &__search-bar{

    }

    &__result-count{
        font-family: $fontFamily-secondary;
        font-size: 0.9rem;
        margin: 10px 0 20px 0;
        color: $gray-600;
    }
    &__result{
        max-height: 70vh;
        overflow-y : auto;
    }
}
.statusBox-container {

    display: flex;
  ;
    align-items: center;
    border-radius: 6px;
    padding: 1px;
  


    &__icon{
      margin: 0 10px;

    }
  &__label {

    padding: 0;
    margin: 0;

    
  }
}

.select-grouped-action-container{
    display: flex;
    border: 1px solid #E0E0E0;
    border-radius: 20px;
    padding: 15px ;
    gap: 20px;

    position: relative;
    &__title{
        position: absolute;
        background-color: white;
        color: #717171;
        z-index: 1;
        font-size: 0.7rem;
        line-height: 0.7rem;
        top: -6px;
        padding: 0 5px;
    }
}
@import "../../../styles/theme/variables.scss";


.pagination{
    display: flex;
    flex-direction: row;

    &--centered{
        justify-content: center;
    }
}
.button{
    transition: all 0.1s ease-in-out;
    background-color: transparent;
    margin: 5px;
    width: 35px;
    height: 35px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    
    &__label{
        color: $secondaryColor-500;
 
        &--actived{
            color: $gray-100 
        }

        
    }

    &--actived{
        background-color: $secondaryColor-500;
        color: $gray-100;
        &:hover{
            background-color:  $secondaryColor-500;
        }
    }
    &--not-actived{
        &:hover{
            background-color: $gray-200;
        }
    }


    &--prev{
        border-radius: 50px 20px 20px 50px;
    }

    &--next{
        border-radius: 15px 50px 50px 15px;
    }

    &__icon{
        &--next{
            margin-right: 2px
        }

        &--prev{
            margin-left: 2px
        }
        
    }

}
.button__icon-arrow {
    &--prev {
      rotate: 180deg;
    }
        &:hover{
            background-color: $gray-200;
        }
  }
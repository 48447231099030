@import "../../../styles/theme/variables";
.button {
    all: unset;
    width: fit-content;
    transition: ease-in-out 0.2s;
}

.link{
    text-decoration: none;
    color: inherit;
}
.btn-content{
    width: fit-content;
    display: flex;
    padding: 10px 20px;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    font-family: $fontFamily-secondary;

    transition: ease-in-out 0.2s;

    


    &__icon-type{
    &--right{
       margin-left: 10px;

    }
    }   
    &__icon-component-left{
        margin-left: -10px;
        margin-right: 10px;
    }
    &__icon-component-right{
        margin-left: 10px;

        &--no-label{
            margin-left: 0px;
        }
    }
    cursor: pointer;
    &--primary{
        background-color: $primaryColor-500;
        color: $secondaryColor-500;
        &--not-disabled {

            &:hover{
   
            background-color: $primaryColor-600;
        }}
    }


    &--outlined{
        
        border: 1px solid $primaryColor-500;
        color: $primaryColor-500;
    }

    &--red{
        background-color: $red-500;
        color: white;
        &--not-disabled {

            &:hover{
            background-color: $red-600;

        }}
    }

    &--black{
        background-color: #000000;
        color: white;


        &--not-disabled {

            &:hover{
                background-color: #4a4a4a;
               
            }
          }
    }

    &--gray{
        background-color: $gray-200;
        &--not-disabled {

            &:hover{
   
            background-color: $gray-300;
        }}
    }

    &--green{
        background-color: $green-500;
        color: white;
        &--not-disabled {

            &:hover{
            background-color: $green-600;
        }}
    }


    &--tercery{
        background-color: transparent;
        color: $secondaryColor-400;
        font-size: 0.9rem;
        &--not-disabled {

            &:hover{
            background-color: #6e6e6e1b
        }}
    }
    &--disabled {
        cursor: default;

        opacity: 0.5;
      }
    &--dark-parimary{
        color: rgb(27, 27, 27) ;
        background-color: rgb(255, 255, 255) ;

        &:hover{
            background-color: rgb(187, 187, 187);
        }
    }

    &__label{
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
    }
    &__arrow-icon{
        margin-left: 20px;
        width: 20px;
    }

}
.loading{
    margin-bottom: -3px;
    display: flex;
    justify-content: center;
    min-width: 100px;
}
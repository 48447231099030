@import './../../../../../styles/theme/variables';
.single-team-list{
    margin: 20px 0;
    width: clamp(400px, 90vw,520px);
   

}
.tag-list{
    display: flex;

}

.badge{
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    height: 35px;
    margin: 0 2px;
    width: fit-content;


    &--black{
        color: white;;
        background-color: $gray-800;
    }
    &--primary{
        color: black;
        background-color: $primaryColor-500;

    }

    &--text{
        font-family: $fontFamily-secondary;
        font-size: 12px;
        
    }

    &--image{
        margin-right: 5px;
    }
}

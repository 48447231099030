.project-list{
    margin: 0;
    padding: 0;
    list-style: none;
  
    &--item{

        margin: 0;
        padding: 0;

        margin: 20px 0;

    }
}
.screen{
    display: flex;
    align-items: center;
    justify-content: center;
    
    height: 300px;
    flex-direction: column;

    &__icon{
        width: 10%;
        min-width: 60px;
        max-width: 200px;
    }
}
@import "../../../styles/theme/variables";

$border-color: rgba(107, 107, 107, 0.109);
$border-raduis: 15px;
.table {
  width: 100%;
  border-collapse: collapse;
  border: none;
  font-size: 0.9rem;

  &--dark {
    color: white;
  }
  border-collapse: separate;
  border-spacing: 0 10px;
  color: $secondaryColor-500;
}
.table-header-row {
  text-align: left;
  border-bottom: 0.5px solid #f5f5f539;
  transition: all 0.2s ease-in-out;

  &__cell {
    transition: all 0.2s ease-in-out;
    padding: 10px 0;
    font-weight: 100;
    color: $gray-600;
    font-size: small;

    &--dark {
      color: rgba(255, 255, 255, 0.479);
    }
  }

  &__cell:first-child {
    padding-left: 10px;
  }
}

.clickable {
  cursor: pointer;
}

.table-body-row {

  transition: all 0.2s ease-in-out;
  z-index: 1;
  border-radius: 10px;
  position: relative;
  &__pin-row{
    background-color: $red-500;
    height: 10px;
    width: 10px;
    left: 0;
    border-radius: 100px;
    position: absolute;
  }

  &--dark {
    .table-body-row__cell {
      background-color: #f5f5f512; // Applique un fond gris à une ligne sur deux
    }

    &:hover {
      .table-body-row__cell {
        background-color: #f5f5f523;
      }
      .button-action-container {
        opacity: 1;
      }
    }
  }


  &--default {
    &:nth-child(even) {
    }
    .table-body-row__cell {
      background-color: white;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      .table-body-row__cell {
        background-color: #81818109;
      }

      .button-action-container {
        opacity: 1;
      }
    }
  }

  line-height: 50px;

  &__cell {
    border: 0;
    &--btn {
      text-align: end;
    }
  }

  &__cell:first-child {
    border-top-left-radius: $border-raduis;
    border-bottom-left-radius: $border-raduis;
    border-left: 1px solid $border-color;
    padding-left: $border-raduis;
  }
  &__cell:last-child {
    border-top-right-radius: $border-raduis;
    border-bottom-right-radius: $border-raduis;
    border-right: 1px solid $border-color;
    padding-right: $border-raduis;
  }
}

.button-action-container {
  transition: all 0.3s ease-in-out;
  opacity: 0;
  z-index: 20;
  position: relative;
  &__button {
    transition: all 0.3s ease-in-out;
    margin: 0 5px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-color: transparent;

    &--default {
      &:hover {
        background-color: #0000000d;
      }
    }

    &--dark {
      &:hover {
        background-color: #00000034;
      }
    }
  }
}

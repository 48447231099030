/*
* COLORS
* Brand colors ..... These are the main brancd colors. They will be used the most
* Neutral colors.....White and black, gray scale, secondary colors.
* Utility colors.....Other colors to represent states: info, warning, success, etc...
*
* TYPOGRAPHY
* Font Families.....
*
* BREAKPOINT
* Breakpoint.........................Global responsive breakpoints definitions
* 
* SPACING
* Spacing............................Global fixed set of spacing variables
* 
*/

/*=============================================
=            COLORS           =
=============================================*/

/* Brand colors */


$primaryColor-100: #fef9db;
$primaryColor-200: #fcf3b7;
$primaryColor-300: #fbec93;
$primaryColor-400: #f9e66f;
$primaryColor-500: #f8e04b;
$primaryColor-600: #c6b33c;
$primaryColor-700: #95862d;
$primaryColor-800: #635a1e;
$primaryColor-900: #322d0f;


$secondaryColor-100: #cccccc;
$secondaryColor-200: #999999;
$secondaryColor-300: #666666;
$secondaryColor-400: #333333;
$secondaryColor-500: #000000;
$secondaryColor-600: #000000;
$secondaryColor-700: #000000;
$secondaryColor-800: #000000;
$secondaryColor-900: #000000;

/* Neutral colors */

$gray-100:#F5F5F5;
$gray-200:#e4e3e3;
$gray-300:#dedede;
$gray-500:#d9d9d9;
$gray-600: #a7a7a7;
$gray-700: #5c5c5c;
$gray-800: #434343;
$gray-900: #2c2c2c;


$red-500:#be3e3e;
$red-600:#943131;

$green-500:#6ca552;
$green-600:#598A42;

$blue-500: #0F7494;







/*=============================================
=            Typography            =
=============================================*/


@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


$fontFamily-primary: 'Ubuntu', sans-serif;
$fontFamily-secondary: 'Ubuntu', sans-serif;


@mixin blur-background-color() {
    background-color:  #ffffffb6; 
    backdrop-filter: blur(10px);
    --tw-backdrop-blur: blur(10px);
}
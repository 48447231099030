@import "../../../../../styles/theme/variables.scss";
.modal-search{
    position: fixed;
    background-color: white;
    z-index: 10;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &__title{
        font-size: 2rem;
        font-family: $fontFamily-primary;
        font-weight: 500;
        margin: 20px 0;
    
    }
}

.content-main{
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-bottom: 20px;
  
}

.content-side{
    &--member{
        width: 250px;
        min-width: 310px;
    }
    &--search-employee{
    
        width: 100%;
    }

    &__title{
        font-size: 1.5rem;
        font-family: $fontFamily-primary;
        font-weight: 500;
       
    }

    &__sub-title{
        font-size: 0.9rem;
        font-family: $fontFamily-secondary;
        font-weight: 300;

    
    }
}
.separator-container{
    display: flex;
    align-items: center;
    &__separator{
        width: 1px;
        background-color: $gray-200;
        opacity: 0.5;
        height: 90%;
        margin: 0 20px;
    }
}

.close-btn{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;

    transition: ease-in-out 0.2s;
    padding: 5px 10px 5px 3px;
   
    &:hover{
        background-color: $gray-100;


    }
    &__icon{
        font-size: 1.5rem;
 
    }

    &__label{
        font-size: 1rem;
        font-family: $fontFamily-secondary;
        font-weight: 300;
        margin-left: 10px;
    }
}

.btn-save{

}
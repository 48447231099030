@import "../../../../styles/theme/variables";

.empty-list-icon {
   
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    


    &__text {
        margin-top: 30px;
        font-size: 1rem;
        line-height: 1.6rem;
        text-align: center;
        font-family: $fontFamily-secondary;
    }
}

@import '../../../styles/theme/variables';
.button {
    all: unset;
  
    width: fit-content;
;
  

}

.link{
    text-decoration: none;
    color: inherit;
}
.btn-content{
    width: fit-content;
    display: flex;
    padding: 10px 20px;
    border-radius: 20px;
    align-items: center;
justify-content: center;
    font-family: $fontFamily-secondary;

    transition: ease-in-out 0.2s;

    &__icon-component-left{
        margin-left: -10px;
        margin-right: 10px;
    }
    &__icon-component-right{
        margin-left: 10px;

        &--no-label{
            margin-left: 0px;
        }
    }
    cursor: pointer;
    &--primary{
        background-color: $primaryColor-500;
        &--not-disabled {

            &:hover{
   
            background-color: $primaryColor-600;
        }}
    }



    &--red{
        background-color: $red-500;
        color: white;
        &--not-disabled {

            &:hover{
            background-color: $red-600;

        }}
    }

    &--black{
        background-color: #000000;
        color: white;


        &--not-disabled {

            &:hover{
                background-color: #4a4a4a
            }
          }
    }

    &--gray{
        background-color: $gray-200;
        &--not-disabled {

            &:hover{
   
            background-color: $gray-300;
        }}
    }

    &--green{
        background-color: $green-500;
        color: white;
        &--not-disabled {

            &:hover{
            background-color: $green-600;
        }}
    }


    &--tercery{
        background-color: transparent;
        color: $secondaryColor-500;
        &--not-disabled {

            &:hover{
            background-color: #6e6e6e1b
        }}
    }
    &--disabled {
        cursor: default;

        opacity: 0.5;
      }

    &__label{
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
    }
    &__arrow-icon{
        margin-left: 20px;
        width: 20px;
    }

}
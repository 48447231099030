@import '../../../../../styles/theme/variables';
.search-pick{
    &__result{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        max-height: 70vh;
        overflow-y : auto;
  
    }

    &__result-count{
        font-family: $fontFamily-secondary;
        font-size: 0.9rem;
        margin: 10px 0 20px 0;
        color: $gray-600;
    }
}
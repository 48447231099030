@import '../../../../../../styles/theme/variables';
.background{
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: $gray-700;
    z-index: 1000;
    justify-content: center;
    @include blur-background-color();
    
}

.modal-card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
    max-width: 600px;
    max-height: 200px;
    min-width: 300px;
    width: 80%;
    height: fit-content;
    min-height: 200px;
    justify-content: space-between;
    background-color: white;
    border-radius: 30px;
    padding: 40px 20px;
    filter: drop-shadow(5px 5px 15px #11111132);
    overflow: hidden;

    &__title{
        font-size: 2rem;
        font-family: $fontFamily-secondary;
        font-weight: 500;
        text-align: center;
        margin: 0;
    }

    &__content{
        margin: 0;
        font-size: 1rem;
        font-family: $fontFamily-secondary;
        font-weight: 300;
        text-align: center;
    }

    &__footer{
        display: flex;
    }
}
.btn{
    margin: 10px;

    &:hover{
       
    }
}
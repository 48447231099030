@import './../../../../../../styles/theme/variables';

.button-import-csv{
    background-color: $primaryColor-500;
    border: none;
    height: 50px;
    padding: 10px 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    cursor: pointer;
    &__icon{
        margin-left: 10px;
    }

    &:hover{
        background-color: $primaryColor-600;
    }
}
.container-btn{
    position: relative;
    height: 100%;
    width: 50%;

}


.input-file-item{
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;

}

.modal{
    z-index: 100;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    @include blur-background-color();
    display: flex;
    justify-content: center;

    &__content{
        filter: drop-shadow(5px 5px 15px #11111132);
        min-width: 240px;
        max-width: 550px;
        backdrop-filter: blur(10px);
        height: 400px;
        width: 50%;
        position: relative;
        margin-top: 10%;
        background-color: white;
        border-radius: 40px;
    }
}

.container-btn-chose-file{


    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 40px;

    &__btn-close{
        position: absolute;
        top: 15px;
        right: 20px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        height: 40px;
        width: 40px;

        transition: all 0.3s;

        background-color: rgba(0, 0, 0, 0);
        border: none;
        cursor: pointer;
        &:hover{
            background-color: rgba(0, 0, 0, 0.1);
        }

    }

    &__btn-send{
 
        margin: 0;
        margin-left: 50%;
        transform: translateX(-50%);

        background-color: $primaryColor-500;
        border: none;
        font-family: $fontFamily-secondary;
        height: 40px;
        padding: 10px 20px;
        border-radius: 20px;
        transition: all 0.3s;



        &--disabled{
            opacity: 0.4;
            background-color: $gray-500;
        }
        &--active{
            cursor: pointer;
            &:hover{
                background-color: $primaryColor-600;
            
            }
        }
    }
}

.file-upload {
    margin-top: 50px;
    height: 200px;
    width: 90%;
    border-radius: 20px;
    transition: all 0.3s;

    text-align: center;
    border: 2px dashed rgba(143, 154, 166, 0.26);

    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover{
        border: 2px dashed rgb(210, 227, 244, 0);
        background-color: rgba(227, 186, 2, 0.109);
    }
  }
@import '../../../../../../styles/theme/variables';
.form-struct{
    display: relative;


    height: 100%;

    &__submit-btn{
       
        margin: 30px 0;
        margin-top: 100px;
    }
}
.footer-bloc{

    display: flex;

}

.footer-content-side{

    &--left{
        width: 50%;
        min-width: 200px;
        margin-right: 20px;
    }

    &--right{
        width: 50%;
        min-width: 200px;
    }

}
.content-side-item{
    width: 90%;
    display: flex;
    flex-direction: column;
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 0px 20px 20px 20px;

    &--error{
        border: 1px solid #d60808 !important
    }

    &__btn{
        display: flex;
        margin: 10px 0;

        &--project{

        }
    }

    &__title{
        font-family: $fontFamily-secondary;
        font-size: 2rem;
        font-weight: 400;
 

        &--error{
            color: #d60808 !important
        }
    }

    &__content{
        &--project{
            margin-bottom: 10px;
        }
    }


}
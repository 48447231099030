@import "./../../../styles/theme/_variables";

.list-box-item {
  position: relative;
  text-decoration: none !important;

  max-width: 500px;
  min-width: 300px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  padding: 1rem;
  color: $secondaryColor-500;

  background-color: $gray-100;
  border-radius: 30px;
  padding: 20px 30px;
  transition: all 0.3s ease-in-out;
  


  &--clickable {
    cursor: pointer;

    &:hover {
      background-color: #f2f2f2;

      .check-case {
        opacity: 1;
      }

      .right-btn {
        transition: all 0.3s ease-in-out;
        margin-right: -10px;
      }
    }
  }
}

.content {
  width: 100%;
}
.content-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header-title {
    margin: 0;
    margin-bottom: 7px;
    font-size: 0.8rem;
    font-weight: 300;
    color: $gray-700;
    height: 15px;

    &--right {
      text-align: right;
    }
  }

  &__sub-title {
    margin: 0;
    margin-top: 10px;
    font-size: 0.9rem;
    font-weight: 300;
    color: $gray-700;

    &--right {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    &--with-bg {
      text-align: right;
      font-size: 0.7rem;
    }
    &__text {
      font-size: 0.7rem;
      font-weight: 300;
      margin: 0;

      &--right {
        text-align: right;
      }

      &--with-bg {
        padding: 5px 10px;
        border-radius: 10px;
      }
      color: #5d5535;
    }
  }
}

.title-container {
  display: flex;
  margin: 0;

  &--right {
    justify-content: flex-end;
  }
  &__label {
    color: $secondaryColor-500;

    font-size: 0.7rem;

    top: 3px;
    margin: 0;
    margin-left: 3px;
  }
  &__title {
    font-size: 1.3rem;
    font-weight: 400;
    color: $secondaryColor-500;
    margin: 0;

    &--right {
      text-align: right;
    }
  }
}
.right-btn {
  transition: all 0.3s ease-in-out;
  margin-right: 0;
}
.bottom-content {
  margin-top: 10px;
}

.pin {
  height: 20px;
  width: 20px;
  background-color: $primaryColor-600;
  border-radius: 100px;
  position: absolute;
  top: -1px;
  right: -1px;
}

.check-case {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
}

@import '../../../styles/theme/variables';
.text-box{
    width: 100%;
    min-height: 80px;
    height: 100%;
    background-color: $gray-100;

    &--primary{
        background-color: $primaryColor-500;
    }


    border-radius: 20px;
    padding: 5px 0;

}

.text-container{

    font-family: $fontFamily-primary;
    display: flex;
    flex-direction: column;
    margin: 15px;
    &__title{
        margin: 0;
        font-weight: 100;
        margin-right: 10px;
        font-size: 0.8rem;
    }
    &__text{
        margin: 0;
        margin-top: 5px;
        font-weight: 800;
        font-size:1rem;
    }
    &__render{
        margin-top: 10px;
    }
}

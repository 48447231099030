@import "../../../styles/theme/variables.scss";
.timeline{

    display: flex;

    .line{
     
   
        position: relative;
        z-index: 1;
        min-width: 8px;
        margin-left: 10px;
        margin-top: 10px;
        border-radius: 100px;
      
      
        background-color: $gray-300;
       
        
        &--transparent{
           opacity: 0;
        }
        overflow: hidden;
   

        &__full{
            z-index: 0;
            width: 100%;
       
            background-color: #A6C185;
            position: absolute;
            top: 0;
            bottom: 0;

        }
    }
}
.content{
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    margin-top: 19px;

    &__item{
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 1;
        margin-left: -18px;
        margin-top: -14px;
        margin-bottom: 10px;
  

        &__line{
            margin-top: 18px;
            min-width: 8px;
            height: calc(100% + 10px);
            background-color: $gray-300;
            border-radius: 100px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }
 
}

.last-point{
    margin-top:-7px;
    margin-left: 28px;
}

.timeline-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    &__dote{
        z-index: 10;
       position: absolute;
        margin-left: -25px;

        width: 22px;
        height: 22px;
        top: 0;
        border-radius: 100px;
        background-color: $gray-300;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);

        &--first{
    
           height: 34px;
              width: 34px;
              margin-left: -31px;
              margin-top: -5px;
        }
 
  
        &--active{
           // background-color: #A6C185;
        }

 
     
    }

}

.icon{
    margin-top: -0.5px;
}

.timeline-item-content{
    margin-top: 0px;
    margin-left: 15px;
    margin-bottom: 20px;


    &__title{
        font-family: $fontFamily-secondary;
        font-size: 13px;
       line-height: 16px;
       margin: 0;
        font-weight: lighter;
    }

    &__description{
        margin: 0;
        font-family: $fontFamily-primary;
        font-size: 10px;
        line-height: 13px;
        opacity: 0.5;
        margin-left: 3px;
    }

    &__sub-description{
        margin-top: 5px;
        margin-bottom: 2px;
        font-family: $fontFamily-primary;
        font-size: 11px;
        line-height: 13px;
        opacity: 0.5;

        background-color: $gray-300;

        padding: 8px;
        border-radius: 9px 9px 9px 0; 
    }


}
@import '../../../styles/theme/variables';
.file-container{
    background-color: $gray-100;
    border-radius: 20px;

    padding: 15px;
    &__title{
        font-size: 0.8rem;
        font-family: $fontFamily-primary;
    
        font-weight: 300;
        margin: 0;


    }
}
.file-list{

    margin: 0px;
    padding: 0px;
}

.file-item{

    width: 92%;
    max-width: 400px;
    border-radius:15px ;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    background-color: $gray-200;
    padding: 10px 15px;
    padding-right:10px ;
    margin: 15px 0;
    &__name{
        font-size: 1rem;
        font-family: $fontFamily-primary;
    }

}

.btn-keyboard{
    display: flex;
    &__btn{
        margin: 0 5px;
    }
}

.btn{
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    height: 35px;
    width: 40px;
    border: none;
    background-color: $gray-700;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover{
        background-color: $gray-900;

    }

    

}

.general-info {
  margin: 10px 0 10px 0;
  max-width: 800px;
}

.row {
  display: flex;
  flex-direction: row;

  &--description {
    height: fit-content;
  }

  &--files {
    height: auto;
  }

  &--main {
    height: 100%;
  }
}

.column {
  height: 100%;
  width: 100%;

  &--left {
    margin-right: 0px;
    width: 60%;
  }

  &--right {
    margin-left: 20px;
    min-width: 100px;

    flex: 1;
    height: 100%;
  }
}

.item {
  margin: 15px 0;
  height: 100%;

}

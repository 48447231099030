@import "../../../../../styles/theme/variables";
.container{
    position: relative;
}
.members-list {
    position: relative;
    margin-top: 0;
    list-style: none;
    


    &--row{
        display: flex;
        flex-wrap: wrap;
    }
    
    &__items{

    
        margin: 10 0;
        padding: 0;
        height: 90px;
        max-width: 300px;
        &--row{
            margin: 10px 10px;
            min-width: 250px
        }
    }


}

.empty-list-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    &__icon{
        opacity: 0.5;
    }

    &__message{
        text-align: center;
        font-family: $fontFamily-secondary;
        font-weight: 200;
        max-width: 200px;
    }
}

.loader-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
}

.fade-effect{


    
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 20px;
 

    &--bottom{
        bottom: 0;
        background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
    &--top{
        top: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    }
}

.gap{
    height: 15px;
}
@import "../../../styles/theme/variables";
.close-btn{
    text-decoration: none;
    color: $secondaryColor-500;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 100px;
    width: fit-content;
    cursor: pointer;

    transition: ease-in-out 0.2s;
    padding: 5px 15px 5px 5px;
    max-height: 60px;
    min-height: 40px;
   
    &:hover{
        background-color: $gray-100;


    }
    &__icon{
        font-size: 1.5rem;
 
    }

    &__label{
        font-size: 1rem;
        font-family: $fontFamily-secondary;
        font-weight: 300;
        margin-left: 10px;
    }
}
@import "../../../styles/theme/variables";
.select-button{
    transition: ease-in-out 0.2s;
    &__label{
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        font-size: 14px;
        color: $gray-700;
        font-family: $fontFamily-secondary;
    }

}

.button{
    position: relative;
    z-index: 2;
    transition: ease-in-out 0.2s;
    border: 0;
    border-radius: 15px;
    padding: 10px 15px;
    background-color: $gray-100;
    font-family: $fontFamily-secondary;
    min-height: 55px;
    cursor: pointer;
    &:hover{
        background-color: $gray-200;
    }

}

.button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    &__selected-content{
        display: flex;
    }

    .__img{
        width: 30px;
        height: 30px;
    }

}

.item{
    background-color: #00000015;
  
    margin: 3px;
    padding: 5px 10px; 
    border-radius: 20px;
    &__name{
        font-size: 14px;

        font-family: $fontFamily-secondary;
    }
    &__delete{
        position: relative;
      
        z-index: 5;
    }
}
.loader{
    height: 10px;
    opacity: 0.2;
}
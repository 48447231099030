.check-case-container{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(124, 124, 124, 0.171);
    height: 20px;
    width: 20px;
    border-radius: 50px;


    cursor: pointer;
    &__icon{
        opacity: 0;
      
    }

    &:hover{
        background-color: rgba(50, 51, 50, 0.36);

        .check-case-container__icon{
            opacity: 0.5;
        }
    }
    &--checked{
        background-color: black;
        .check-case-container__icon{
            opacity: 1;
        }
    }


}

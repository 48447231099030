/*================================================================
 *                TeamHeaderTitle
 *================================================================*/

 @import '../../../../../styles/theme/variables.scss';

 .team-header-info{


    &__title{
        font-size: clamp(2rem, 10vw, 50px);
        font-weight: 400;
        color: $secondaryColor-500;
        margin-bottom: 20px;
    }

    &__creationdate{

        font-size: 0.9rem;
        margin-top: 20px;

        font-weight: 200;
        color: $gray-600;

    }

 }

 .badge-container{
    display: flex;
    margin-bottom: 30px;
 }
 .description{

    max-width: 850px;
    margin-bottom: 30px;

    &__label{
        font-size: 0.8rem;

        font-weight: 200;
        color: $gray-600;
        margin-bottom: 0;

    }

    &__text{
        margin-top: 0;
        font-size: 1;
        line-height: 1.8;
        font-weight: 300;
    }
 }



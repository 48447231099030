@import "./../../../../styles/theme/variables.scss";
.project-info{

    background-color: $gray-100;
    border-radius: 25px;
    padding: 20px;
    position: relative;

    &--clickable{
        cursor: pointer;
        transition: ease-in-out 0.2s;
        &:hover{
            background-color: $gray-200;
        }
    }

    &__delete-btn{
        
        position: absolute;
        top: -2px;
        right: -2px;
        background-color: black;
        z-index: 1;;
    
        border: none;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: ease-in-out 0.2s;

    }
    &__title{
        margin: 0;
        font-size: 1.1rem;
        font-weight: 400;
        color: $secondaryColor-500;
      
    }

    &__description-label{
        margin: 0;
        margin-top: 15px;
        font-size: 0.7rem;
        font-weight: 200;
        color: $gray-600;
       
    }

    &__description-text{
        margin-top: 5px;
        font-size: 0.9;
        line-height: 1.5;
        font-weight: 300;
    }

}
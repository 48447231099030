@import '../../../../../../styles/theme/variables';

.form-team{

    &__input-text{
    margin: 30px 0px;
        &--team-name{

        }

        &--team-description{

        }
    }

}
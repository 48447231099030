.aprove-disprove-view{

    margin-top: 10px;

    &__text{
        width: 100%;
     
    }

}

.keyboard{

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
    height: 100px;

    width: 100%;

    &__button{
        margin: 5px;
    }



}







@import "../../../styles/theme/variables";
.page{

    &__return-btn{
        margin-bottom: 20px;
    }

    &__title{
        font-size: clamp(2rem, 10vw, 50px);
        font-weight: 400;
        color: $secondaryColor-500;
        margin-bottom: 40px;
    }
}